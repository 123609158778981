<template>
  <!-- POPUP CODE STARTS -->
  <div class="popup_container">
    <div class="popup_body_container">
      <div
        v-if="heading || headerCloseFun"
        class="popup_header_container p-4 flex justify-between items-center shadow"
      >
        <h4 class="text-xxs uppercase font-semibold">
          {{ heading }}
        </h4>
        <button
          @click="headerCloseFun"
          class="text-sm font-normal uppercase text-red_"
          style="color: #cf634b"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="popup_body py-2 px-4 text-xxs">
        <slot> </slot>
      </div>
    </div>
  </div>
  <!-- POPUP CODE ENDS -->
</template>
<script>
export default {
  props: {
    heading: {
      type: String,
      default: "",
    },
    headerCloseFun: {
      type: Function,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.popup_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.popup_body_container {
  min-width: 20%;
  max-width: 90%;
  max-height: 95%;
  height: 100%;
  background: var(--sidebarbg);
  color: var(--textColor);
  overflow: auto;
}
.popup_header_container{
  height: 42px;
  position: sticky;
  top: 0;
  background: var(--sidebarbg);
  z-index: 9999;
}
</style>