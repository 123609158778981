<template>

<!-- by default editing will be disabled, to enable the edit pass---- 'enableEdit' prop -- true
by default action buttons will be disabled, to enable the action buttons pass -- 'enableActionButtons' && 'enableEdit' props --- true -->


  <table :class="{disabledEdit: !enableEdit}">
    <thead v-if="Object.keys(columnNames)?.length">
      <tr>
        <th v-if="enableActionButtons && enableEdit">Action</th>
        <th
          v-for="(columnValues, columnKey, index) in columnNames"
          :key="index"
          :disabled="true"
        >
        <div class="selectFieldContainer flex" style="
          justify-content: center;
          align-items: center;
          flex-direction: row;"
        >
            {{ columnValues?.headerName }}
            <UnitSelect v-if="!isRealTime && columnValues?.unit_selection"
              class="swabsurge_unit_selection"
              style="margin-left: 2px;"
              :isMetrics="isWellMetrics"
              :display-id="displayId"
              :widget-id="widgetId"
              :mnemonic="default_units[mnemonic_prefix + columnKey]?.mnemonic"
              :category="default_units[mnemonic_prefix + columnKey]?.category"
              :pt="'pt-0'"
              :is-custom-events="true"
              :selected-val=" conversionFactors[mnemonic_prefix + columnKey] &&
                  conversionFactors[mnemonic_prefix + columnKey]?.unit &&
                  conversionFactors[mnemonic_prefix + columnKey]?.unit != ''
                    ? conversionFactors[mnemonic_prefix + columnKey].unit
                    : default_units[mnemonic_prefix + columnKey]?.display_unit"
              :standaredUnit="default_units[mnemonic_prefix + columnKey]?.standardUnits"
              :reverse="true"
              :interChange="true"
              @change_unit_handler="(val)=> $emit('changeUnitHandler', val, columnKey)
              "
            />
            <span v-if="isRealTime && columnValues?.unit_selection" class="normal-case"> ({{ default_units[mnemonic_prefix + columnKey]?.display_unit }}) </span>
          </div>
        </th>
      </tr>
    </thead>
    <tbody v-if="rows?.length">
      <tr v-for="(eachRow, rowIndex) of rows" :key="rowIndex">
        <td class="actionBtnsCell" v-if="enableActionButtons && enableEdit">
            <button class="addRowBtn" @click="$emit('addRow', rowIndex)"><i class="fas fa-plus-circle text-xxs addRowBtnIcon"></i></button>
            <button v-if="rowIndex > 0" class="deleteRowBtn" @click="$emit('deleteRow', rowIndex)"><i class="fas fa-trash text-xxs deleteRowBtnIcon"></i></button>
        </td>


        <td v-for="(columnValues, columnKey, cellIndex) in columnNames" :key="cellIndex">
          <!-- INPUT BOX -->
          <input
            v-if="columnValues?.ui_element == 'input'"
            :type="columnValues?.ui_element_type"
            :value="eachRow[columnKey]"
            @change="$emit('updateCellValue', rowIndex, columnKey, $event.target.value)"
          />

          <!-- SELECT BOX -->
          <select 
            v-else-if="columnValues?.ui_element == 'select'"
            :value="eachRow[columnKey]"
            @change="$emit('updateCellValue', rowIndex, columnKey, $event.target.value)"
            >
            <option :value="''" :disabled="true">Select</option>
            <option
              v-for="(option, optionIndex) of columnValues?.ui_element_options"
              :key="optionIndex"
              :value="option"
              :disabled="rows.some((obj)=> obj[columnKey] == option)"
            >
              {{ option }}
            </option>
          </select>
          <!-- <UnitSelect v-if="!isRealTime && columnValues?.unit_selection && eachRow[mnemonic_prefix_selection_column]"
            v-show="insideCellUnitSelection"
            class="swabsurge_unit_selection insideCellUnitSelection"
            style="margin-left: 2px;"
            :isMetrics="isWellMetrics"
            :display-id="displayId"
            :widget-id="widgetId"
            :mnemonic="default_units[`${eachRow[mnemonic_prefix_selection_column].toLowerCase()}` + columnKey]?.mnemonic"
            :category="default_units[`${eachRow[mnemonic_prefix_selection_column].toLowerCase()}` + columnKey]?.category"
            :pt="'pt-0'"
            :is-custom-events="true"
            :selected-val=" conversionFactors[`${eachRow[mnemonic_prefix_selection_column].toLowerCase()}` + columnKey] &&
                conversionFactors[`${eachRow[mnemonic_prefix_selection_column].toLowerCase()}` + columnKey]?.unit &&
                conversionFactors[`${eachRow[mnemonic_prefix_selection_column].toLowerCase()}` + columnKey]?.unit != ''
                  ? conversionFactors[`${eachRow[mnemonic_prefix_selection_column].toLowerCase()}` + columnKey].unit
                  : default_units[`${eachRow[mnemonic_prefix_selection_column].toLowerCase()}` + columnKey]?.display_unit"
            :standaredUnit="default_units[`${eachRow[mnemonic_prefix_selection_column].toLowerCase()}` + columnKey]?.standardUnits"
            :reverse="true"
            :interChange="true"
            @change_unit_handler="(val)=> $emit('unitChangeHandler', `${eachRow[mnemonic_prefix_selection_column].toLowerCase()}` + columnKey, val)"
          /> -->
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr class="text-center" >
        <td :colspan="Object.keys(columnNames)?.length + (enableActionButtons && enableEdit ? 1 : 0)" class="font-semibold capitalize" style="color: red;">
          No records found
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import UnitSelect from "../../Navbars/properties/unitSelectDirectional.vue";
export default {
  emits: ["updateCellValue", "addRow", "deleteRow", "changeUnitHandler", "unitChangeHandler"],
  props: {
    columnNames: {
      type: Object,
      default: {},
    },
    rows: {
      type: Array,
      default: [],
    },
    enableActionButtons: {
      type: Boolean,
      default: false,
    },
    enableEdit: {
      type: Boolean,
      default: false,
    },
    isRealTime:{
        type: Boolean,
        default: false,
    },
    displayId:{
        type: String,
        default: '',
    },
    widgetId:{
        type: String,
        default: '',
    },
    mnemonic_prefix:{
        type: String,
        default: '',
    },
    mnemonic_prefix_selection_column:{
        type: String,
        default: '',
    },
    isWellMetrics:{
        type: Boolean,
        default: false,
    },
    insideCellUnitSelection:{
        type: Boolean,
        default: false,
    },
    default_units:{
      type: Object,
      default: {},
    },
    conversionFactors:{
      type: Object,
      default: {},
    }
  },
  components:{
    UnitSelect,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
table{
  background: var(--nav1BgClrsame);
  width: 100%;
}
input, select {
    padding-inline: 5px;
    border: 0px;
    background: var(--nav1BgClrsame) !important;
    height: 100%;
    width: auto;
    text-align: center;
    max-width: 100%;
    min-width: 100%;
}
select:focus{
  border: 0px;
  outline: 0px;
}
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
tr th{
  padding-inline: 5px;
}
tr th, tr td{
    border: 0.3px solid #525252;
    height: 25px;
}
.actionBtnsCell{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.addRowBtnIcon{
  color: green;
}
.deleteRowBtnIcon{
  color: red;
}
.disabledEdit{
  pointer-events: none;
}
</style>