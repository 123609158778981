<template>
    <div>
        <label>
            {{ labelName }}
        </label>
        <div class="flex justify-center items-center">
          <input type="number" :value="data" @change="$emit('numberChanged', $event.target.value)" :disabled="!enableEdit"/>
          <UnitSelect v-if="!isRealTime && isUnitSelection"
            :class="'swabsurge_unit_selection'" style="margin-left: 2px;"
            :isMetrics="isWellMetrics"
            :display-id="displayId"
            :widget-id="widgetId"
            :mnemonic="mnemonic"
            :category="category"
            :pt="'pt-0'"
            :is-custom-events="isCustomEvents"
            :selected-val="selectedVal"
            :standaredUnit="standaredUnit"
            :reverse="reverse"
            :interChange="interChange"
            @change_unit_handler="(val)=> $emit('changeUnitHandler', val)
            "
          />
          <span v-if="isRealTime && isUnitSelection" class="normal-case" style="margin-left: 2px; padding-right: 5px;"> {{ displayUnit }} </span>
      </div>
    </div>
</template>
<script>
import UnitSelect from "../../Navbars/properties/unitSelectDirectional.vue";
export default {
    emits: ["numberChanged", "changeUnitHandler"],
    props:{
        labelName:{
            type: String,
            default: '',
        },
        data:{
            type: Number,
            default: '',
        },
        enableEdit:{
            type: Boolean,
            default: false,
        },
        isRealTime:{
            type: Boolean,
            default: false,
        },
        displayId:{
            type: String,
            default: '',
        },
        widgetId:{
            type: String,
            default: '',
        },
        mnemonic:{
            type: String,
            default: '',
        },
        category:{
            type: String,
            default: '',
        },
        selectedVal:{
            type: String,
            default: '',
        },
        standaredUnit:{
            type: String,
            default: '',
        },
        displayUnit:{
            type: String,
            default: '',
        },
        isUnitSelection:{
            type: Boolean,
            default: false,
        },
        isWellMetrics:{
            type: Boolean,
            default: false,
        },
        isCustomEvents:{
            type: Boolean,
            default: false,
        },
        reverse:{
            type: Boolean,
            default: false,
        },
        interChange:{
            type: Boolean,
            default: false,
        },
    },
    components:{
      UnitSelect,
    },
    data() {
      return{

      }  
    },
}
</script>
<style scoped>
select,
input{
  background: var(--nav1BgClrsame);
  border: 0px;
  padding-inline: 5px;
  height: 25px;
  width: auto;
}
select:disabled,
input:disabled{
  opacity: 0.75
}
select:focus{
  border: 0;
  outline: 0;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.inputFieldContainer{
  flex-direction: column;
  align-items: start;
  column-gap: 5px;
}
</style>

